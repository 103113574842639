import React from "react"
import { Page } from "../../shared/Page/Page"
import styled from "styled-components"
import { H1, H2, P } from "../../shared/Ui/Typography/Typography"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import Helmet from "react-helmet"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 80rem 0;
    text-align: center;

    > * {
        text-align: center;
    }

    .buttons {
        padding: 30rem 0 0;
    }
`

const OnboardingConfirmation = () => {
    return (
        <Page constrained disableCta>
            <Helmet>
                <meta name={"robots"} content={"noindex"} />
            </Helmet>
            <Container>
                <H1>You're all set!</H1>
                <H2>Your booking has been confirmed 🎉</H2>
                <P>We're looking forward to speaking with you soon.</P>
                <div className={"buttons"}>
                    <a href={"//app.23shout.com"} tabIndex={-1}>
                        <FlatButton color={"primary"} size={"larger"}>
                            Return to 23shout
                        </FlatButton>
                    </a>
                </div>
            </Container>
        </Page>
    )
}

export default OnboardingConfirmation
